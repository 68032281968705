const config = {
  ENVIRONMENT_NAME: 'dev',
  forwoodId: {
    URL: 'https://id.dev.exploration.forwoodsafety.com',
    USER_TOKEN_URL: 'https://6t1vfo4b52.execute-api.us-west-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://scy9ofwkph.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'teams-container-1.10.8',
    HOSTNAME: 'https://teams.dev.exploration.forwoodsafety.com',
    TEAMS_MICROFRONTEND_URL: 'https://teams-service.dev.exploration.forwoodsafety.com',
    TARGETS_MICROFRONTEND_URL: 'https://targets.dev.exploration.forwoodsafety.com',
    TEAMS_ACCESS_ROLE: 'TeamsAccess',
    TEAM_TARGETS_ACCESS_ROLE: 'TeamTargetsAccess',
  },
  configurationService: {
    URL: 'https://config.dev.exploration.forwoodsafety.com',
    WEBSOCKET: 'wss://qud3gskc58.execute-api.us-west-2.amazonaws.com/dev'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
